import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular/standalone';
import { ToastOptions } from '@ionic/angular';

@Injectable()
export class ToastService {
	constructor(private toastCtrl: ToastController) {}

	async presentToast(
		msg: string,
		position: 'top' | 'bottom' | 'middle' | undefined = 'top',
		duration: number = 1500,
	) {
		const options: ToastOptions = {
			duration: duration,
			message: msg,
			position: position,
		};
		const toast = await this.toastCtrl.create(options);
		await toast.present();
	}
}

